.courses__container {
  margin: 5px 5px 5px 5px;
  padding: 10px 10px 10px 10px;
  background: #ffffff;
  box-shadow: 4px 4px 4px 10px #f6f8fa;
  border-radius: 10px;
  margin-bottom: 20px;
}
.course__image {
  width: 250px;
  height: 200px;
  border-radius: 10px;
  margin: 10px 10px 10px 10px;
}
.course__title {
  margin-left: 20px;
  color: rgb(60, 60, 60) 80%;
}
.course__subtitle {
  color: #7a899a;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  margin-left: 20px;
  text-align: start;
}
.tags__container {
  margin-left: 20px;
}
.tags__body {
  margin: 5px 5px 5px 5px;
  // padding: 5px 10px 5px 10px;
  // border-radius: 5px;
  // background-color: #eff3f8;
  // border: none;
}
.tags__title {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #eff3f8;
  border: none;
  font-size: 14px;
  font-family: "Poppins";
}
.icons__container {
  // margin-left: 20px;
  display: flex;
}
.coursetitle__container {
  width: 700px;
  max-width: 700px;
}
.count__title {
  margin: 10px 10px 10px 10px;
  font-family: "Poppins";
  font-size: 14px;
}
