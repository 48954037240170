.navbar {
  background-color: #325288 !important;
  // position: fixed;
  // width: 100vw;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff;
  font-family: "Poppins";
  font-size: 20px;
}
.navbar-light .navbar-nav .nav-link {
  color: #bdc7c9;
  font-family: "Poppins";
  font-size: 20px;
  padding: 10px 20px 10px 0;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar .collapse .nav-avatar {
  padding: 0;
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.nav__active {
  color: #fff;
  font-size: 16px;
}
.nav__inactive {
  color: #bdc7c9;
  font-size: 16px;
}
.nav-link {
  font-size: 18px !important;
}
