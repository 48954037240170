.form__heading {
  color: #012432;
}
.form__subheading {
  color: rgba(57, 70, 73, 1);
}
.social__logo__inform {
  margin-bottom: -65px;
  margin-left: 20px;
  height: 20px;
  width: 20px;
}
.social__logo__inform__two {
  height: 30px;
  width: 30px;
}
.img__container {
  width: 40px;
  height: 50px;
  padding: 5px;
  border-width: 4px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: solid #ced4da;
  border-top: solid #ced4da;
  border-bottom: solid #ced4da;
  border-right: none;
}
.form-check-input {
  background-color: rgba(127, 201, 142, 0.33);
  width: 30px;
  height: 30px;
  border: 2px solid #7fc98e;
}
.form-check-input:checked[type="checkbox"] {
  background-color: #3fd65de6;
  border: 2px solid #7fc98e;
}

/* input with icon */
.has-search .form-control {
  padding-left: 2.375rem;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: green;
}
