// .signup {
//   display: flex;
//   align-items: center;
//   height: 100vh;
// }
// .name__container {
//   display: flex;
//   flex-direction: row;
// }

// .form-control {
//   height: 50px;
//   border-radius: 10px;
//   border-width: 4px;
// }

.signupform__button {
  width: 100%;
  height: 50px;
  background-color: #00adb5;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}

// @media (max-width: 768px) {
// }

.signin__container {
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  height: 100vh;
}
.content-box > label {
  color: #4a4e6d;
  font-size: 13px;
  font-weight: 500;
  line-height: 29px;
}
