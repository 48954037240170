.home__container {
  overflow: hidden;
  // background-color: #f9f9f9;
  margin-top: 70px;
}
.homesidebar {
  overflow-y: auto;
  position: fixed;
  top: 75px;
}
.newsfeedcontainer {
  margin: 0px auto;
}

.homecontainer {
  position: relative;
  background-color: aqua;
  // top: 0px;
  // bottom: 0px;
}
.left {
  left: 50px;
}
.right {
  right: 50px;
}
