.horizontal__card {
  border: none;
  border-radius: 10px;
}
.img__style {
  border-radius: 10px;
  filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
}
.card__title {
  color: #18a0fb;
  font-weight: 600;
}
