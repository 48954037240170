.landingpage {
  margin: 0%;
}
.landing__image {
  background-image: linear-gradient(rgba(35, 34, 34, 0.527), rgba(0, 0, 0, 0.5)),
    url("https://images.unsplash.com/photo-1507842217343-583bb7270b66?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1153&q=80") !important;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.signin__button {
  margin: 20px;
  width: 150px;
  height: 50px;
  background-color: transparent;
  border-radius: 5px;
  border-width: 4px;
  border-color: white;
}
.signup__button:hover {
  // background-color: transparent;
}

.signup__container {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  align-items: center;
}

.signup__form {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.signup__options {
  border-radius: 10px;
  border-width: 4px;
  border-color: white;
  background-color: #d0d4d5;
  margin: 10px;
  display: flex;
}

.social__logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
  position: absolute;
  left: 30px;
  top: 5px;
}
.signup__button {
  border-width: 2px;
  border-color: #fff;
  background-color: #d0d4d4;
  position: relative;
  height: 45px !important;
}
.outline {
  padding: 10px 20px 10px 20px;
  border: 4px solid;
  border-color: #fff;
  width: 500px;
  border-radius: 10px;
}
/* Media Queries */

@media (max-width: 1400px) {
}

@media (max-width: 768px) {
  .signup__button {
    border-width: 4px;
    border-color: #fff;
    background-color: #d0d4d4;
  }

  .signup__form {
    width: 90%;
  }
}

@media (max-width: 480px) {
  // .text {
  //   font-size: 16px;
  // }
}

.facebook__login {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}

.downarrow {
  opacity: 0.5;
  cursor: pointer;
  animation: bounce 2s ease infinite;
}
.downarrow:hover {
  animation: ease-in-out;
  opacity: 100;
  transition: 0.5s ease-in;
}
