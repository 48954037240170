.profile__image {
  margin-top: 50px;
}
.information__container {
  padding: 20px;
}
.cancel__button {
  margin: 10px 10px 10px 10px;
  padding: 10px;
  border: 2px solid #ff3a66;
  color: #ff3a66;
}
.savexit__button {
  margin: 10px 10px 10px 10px;
  padding: 10px;
  background-color: #1ea69a;
  border: none;
  color: #fff;
}
.button__title {
  font-family: "Poppins";
}
