.profile__image__container {
}
.profile__image {
  margin-top: 50px;
}
.profile__detail__container {
  padding: 20px;
}
.profile__name {
  color: #012432;
  font-weight: 600;
}
.profile__desc {
  font-family: "Poppins";
  color: #394649;
}
.profile__designation {
  color: #012432;
}
.followers__container {
  cursor: pointer;
}
.followers__number,
.following__number {
  color: rgba(24, 160, 251, 0.76);
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 600;
}
.followers,
.following {
  color: #012432, 100%;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins";
}
.edit__profile {
  margin: 10px 10px 10px 10px;
  padding: 10px;
  background-color: #ff3a66;
  border: none;
}
.log__out {
  margin: 10px 10px 10px 10px;
  padding: 10px;
  background-color: #18a0fb;
  border: none;
}
.aboutme__section {
  padding: 10px 10px 10px 10px;
  margin-left: 20px;
}
.about__me {
  color: #012432;
}
.mycourses__section {
  margin-left: 20px;
}
