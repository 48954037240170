.card-img-top {
  width: 100%;
  height: 12rem;
}
.card__background {
  background-color: #f6f8fa;
  border: none;
  border-radius: 10px;
  width: 20rem;
  margin: 20px;
}
