 .button2:hover {
 /* transition: ease-in 0.1s; */
      box-shadow:  #325288;
      /* cursor: pointer; */
    
      color: rgb(255, 255, 255);
      word-wrap: normal;
            
      background-color: #fcfcfc;
      color: #325288;
      
     }
    
     .button2{
          background-color: #325288;
      color: rgb(255, 255, 255);
          border-radius: 10px;
          height: 35px;
          width: 150px;
          /* margin-top: 2%; */
          margin-top:-62px;

          position : absolute;
          
          align-items: flex-end;
          justify-content: end;
     }