.dragdrop__container {
  width: 500px;
  height: 320px;
  border-radius: 10px;
  background-color: rgba(182, 213, 234, 0.44);
  display: grid;
  place-items: center;
  cursor: pointer;
}
.createcourse__dragdrop__container {
  width: 500px;
  height: 320px;
  border-radius: 10px;
  background-color: transparent;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.drop__image__container {
  display: grid;
  place-items: center;
  width: 500px;
  height: 320px;
}
.drop__image__container > img:hover {
  opacity: 0.5;
  transition: 1s;
}
.drop__image {
  border-radius: 5px;
   max-width: 100%;
  height: auto;
}

.fileupload__text {
  color: rgba(24, 160, 251, 1);
  text-align: center;
}
