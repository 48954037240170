@font-face {
  font-family: "Poppins";
}

.footer_top_middle {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer_heading {
  text-align: center;
  width: 100%;
  font-size: 22px;
  font-family: "Poppins";
  color: rgba(0, 0, 0, 0.804);
  font-weight: 500;
  margin-top: 10px;
}

.footer_link_box {
  width: auto;
  float: left;
  padding: 0 40px;
  font-family: "Poppins";
  font-size: 16px;
  cursor: pointer;
}
.footer_social_media_box img {
  width: 30px;
}
.footer_social_media_box {
  width: 40%;
}
.sm_icon {
  width: 25%;
  float: left;
  text-align: center;
  filter: drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.25));
}
@media screen and (max-width: 767px) {
  .footer_social_media_box {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .footer_link_box {
    padding: 0 25px;
  }
  .footer_social_media_box {
    width: 100%;
  }
}
