.my_courses_container {
  background-color: #f9f9f9;
  overflow: hidden;
  margin-top: 70px;
}
.my_courses_box {
  width: 100%;
  margin: 10px 0 20px 0;
  float: left;
}
.my_courses_box .card {
  border: none;
  box-shadow: 0px 8px 10px #dfdfdf;
}

// .card {
//   flex-direction: row;
// }

.card_img {
  width: 20%;
  height: auto;
  padding: 1.25rem;
}
.card-body {
  width: 70%;
  height: auto;
}
.card-title {
  font-family: "Poppins-SemiBold";
  color: #000;
  width: 80%;
  float: left;
  font-size: 25px;
}
.edit_delete_box {
  width: auto;
  float: right;
}
.edit_delete_box img {
  width: 20px;
  padding: 0 0 0 10px;
}
.card-text {
  color: #7a899a;
  font-size: 18px;
  width: 100%;
  float: left;
}

.sub_course_box {
  width: 100%;
  float: left;
}
.sub_course_box a {
  color: #000000;
  font-family: "Poppins-Regular";
  text-decoration: none;
  padding: 0 30px 0 0px;
  font-size: 14px;
}
.reviews_views_box {
  width: 100%;
  float: left;
  text-align: right;
}
.reviews_views_box a {
  color: #575758;
  font-size: 17px;
  margin: 0 0 0 20px;
  font-family: "Poppins-Medium";
}
.createcourse__button {
  margin: 10px 10px 10px 10px;
  padding: 10px;
  background-color: #1ea69a;
  border: none;
}
.createcourse__text {
  color: #fff;
  font-family: "Poppins";
}

@media screen and (max-width: 575px) {
  .card {
    flex-direction: column;
  }
  .card-body {
    width: 100%;
    height: auto;
  }
  .card_img {
    width: 100%;
    height: auto;
    padding: 1.25rem;
  }
  .card-title {
    font-size: 23px;
    width: auto;
  }
  .card-text {
    font-size: 16px;
  }
  .sub_course_box a {
    padding: 0 20px 0 0px;
    font-size: 13px;
  }
  .reviews_views_box {
    width: 100%;
    float: left;
    text-align: left;
  }
  .reviews_views_box a {
    font-size: 16px;
    margin: 0 15px 0 0;
  }
}
